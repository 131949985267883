
const Banner = () =>{

    return (
        <>
          <section className="slider-area">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-7 col-md-7">
                            <div className="slider-content">
                                <h6 class="sub-title" data-animation="fadeInUp" data-delay=".2s">KATANA INU</h6>
                                <h2 data-animation="fadeInUp" data-delay=".4s">PC-Game with <span>NFT Ecosystem</span>
                                </h2>
                                <p data-animation="fadeInUp" data-delay=".6s">Mint available now</p>
                                <div className='banner-btn'>
                                    <a href="https://katanainu.com/" target="_blank" rel="noreferrer" className="btnd" data-animation="fadeInUp" data-delay=".8s">Go to  <span>katanainu.com</span></a>
                                    <a className="btnd" target="_blank" rel="noreferrer" href='https://discord.com/invite/katanainu'><i className="fab fa-discord"></i> Join Discord</a>
                                    <a className="btnd" target="_blank" rel="noreferrer" href='https://mint.katanainu.com/'>Mint Here</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-5">
                            <div className="banner_img">
                                <img src="assets/img/slider/banner-img.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner