import { Outlet, Link } from "react-router-dom"

const Navs  = () => {
    return (
        <>
            <nav className="menu-nav">
                <div className="logo">
                    <Link to="/">
                        <img src="assets/img/logo/logo.png" alt="Logo" />
                    </Link>
                </div>
                <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">

{/*
                        <li>
                            <a href="http://play.katanainu.com/" target="_blank" rel="noreferrer">PLAY</a>
                        </li> */}
                        <li className="active"><a target="_blank" rel="noreferrer" href="https://katanainu.com/">Katana Inu</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://kainu.io/">Marketplace</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://katanainu.com/the-game">Why Katana Inu</a>
                        </li>

                        <li>
                            <a target="_blank" rel="noreferrer" href="https://play.katanainu.com/">Play
                            </a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://katanainu.gitbook.io/2/">NFT Paper</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://katanainu.com/assets/resources/katanainuwhitepaper.pdf">WhitePaper</a>
                        </li>

                    </ul>
                </div>
            </nav>
            <Outlet/>
        </>
    )
}

export default Navs
