import React from "react"
//import TabNav from "./tabsnav/TabNav"


const Ecosystem2 = () =>{
    return (
        <>
        {/* unique-area */}
        <section className="unique-area pt-100nft/background">
          <div className="container">
            <div className="row justify-content-center mb-50 pt-100">
                <div className="col-md-8">
                    <div className="section-title text-center">
                        <h2 className='title'>Accessories</h2>
                        <p>For a guaranteed whitelist spot get more points for the raffle:Follow us on Social Media retweet one post and by inviting 5 friends  your chance for a guaranteed whitelist spot gets higher!</p>
                        <span className="traits">165 traits total</span>
                    </div>
                </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="unique-section-btn mb-50">
                  {/* <TabNav datas={tabs} active={1} prefix="tab"/> */}
                  <ul className="accesoris_list nav nav-tabs">
                    <li className="btn">31 weapons</li>
                    <li className="btn">8 Pops</li>
                    <li className="btn">6 skins</li>
                    <li className="btn">10 Mouth</li>
                    <li className="btn">16 Hairs</li>
                    <li className="btn">13 Eyewear</li>
                    <li className="btn">11 Eyes</li>
                    <li className="btn">39 clothes</li>
                    <li className="btn">31 Backgrounds</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="tab-1-nav">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tab_video">
                                    <h3 className="mb-30">NFT ECOSYSTEM for Genesis and Forging Video Explain</h3>
                                    <div className="about-video about_video">
                                        <iframe src={"https://www.youtube.com/embed/N00zOuIwI3M?autoplay=1&iv_load_policy=0&loop=1&playsinline=1&controls=0&mute=1&origin="+window.location.origin} title="YouTube video player" frameBorder={0} allowFullScreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
        {/* unique-area-end */}
        </>
    )
}

export default Ecosystem2