const Footer = () =>{
    return (
        <>
        {/* footer-start */}
        <footer>
            <div className="container">
            <div className="footer-top-wrap">
                <div className="footer-logo-wrap">
                <div className="footer-logo">
                    <a href="/"><img src="assets/img/logo/footer_logo.png" alt="Logo" /></a>

                </div>
                <div className="footer-social">
                    <ul>
                    <li><a target="_blank" rel="noreferrer" href="https://web.facebook.com/profile.php?id=100069383490342"><i className="fab fa-facebook-f" /></a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@katanainu"><i className="fab fa-tiktok" /></a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/katanainu/"><i className="fab fa-instagram" /></a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://twitter.com/katanainu"><i className="fab fa-twitter" /></a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://www.reddit.com/r/katanainu"><i className="fab fa-reddit-alien" /></a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://discord.com/invite/katanainu"><i className="fab fa-discord" /></a></li>
                    </ul>
                </div>
                </div>
                <div className="footer-widget-wrap">
                <div className="widget">
                    <div className="footer-desc">
                    <h6 className="desc-title">Disclaimer</h6>
                    <p>Nothing on this website constitutes financial advice, and it is always recommended to consult a qualified financial
                        advisor before participating in any token or NFT purchases.</p>
                    </div>
                </div>
                <div className="widget">
                    <h5 className="fw-title">Products</h5>
                    <ul className="footer-menu">
                        <li><a target="_blank" rel="noreferrer" href="https://katanainu.com/nftexplain.pdf">NFT Forging Paper</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://pancakeswap.finance/swap?outputCurrency=0x6D6bA21E4C4b29CA7Bfa1c344Ba1E35B8DaE7205">Pancakeswap</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2e85ae1C47602f7927bCabc2Ff99C40aA222aE15&chain=mainnet">Uniswap</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://katanainu.com/assets/resources/katanainuwhitepaper.pdf">Whitepaper</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://katanainu.com/assets/resources/Roadmap1.png">Roadmap</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://katanainu.com/assets/resources/katanainutokenomics.pdf">Tokenomics</a></li>
                    </ul>
                </div>
                <div className="widget">
                    <h5 className="fw-title">need help?</h5>
                    <ul className="footer-menu">
                        <li><a target="_blank" rel="noreferrer" href="https://instagram.com/katanainu">Instagram</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/Katanainu-104918325117822">Facebook</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://t.me/katanainu">Telegram</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@katanainu">Tiktok</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://medium.com/@katanainu">Medium</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://twitter.com/katanainu">Twitter</a></li>
                        {/* <li><a target="_blank" rel="noreferrer" href="/#"><i className="fab fa-youtube" /></a></li> */}
                        <li><a target="_blank" rel="noreferrer" href="https://www.reddit.com/r/katanainu">Reddit</a></li>
                        <li><a target="_blank" rel="noreferrer" href="https://www.discord.gg/katanainu">Discord</a></li>
                    </ul>
                </div>

                </div>
            </div>
            <div className="copyright-wrap text-center">
                <p>KATANA INU IS A PROJECT FROM <span>CHAINVISION GAMES</span></p>
            </div>
            </div>
        </footer>
        {/* footer-start-end */}
        </>
    )
}

export default Footer