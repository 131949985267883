import React from 'react';
import './Nft.css';
const Nft = () =>{
    return (
        <section className="about">
            <div className="about__wrapper">
                <div className="about__img__wrap">
                    <div className="top-shadow"></div>
                    <div className="about__slider__left">
                        <img src="assets/img/images/1.png" alt="ABOUT"/>
                        <img src="assets/img/images/2.png" alt="ABOUT"/>
                        <img src="assets/img/images/3.png" alt="ABOUT"/>
                        <img src="assets/img/images/4.png" alt="ABOUT"/>
                        <img src="assets/img/images/5.png" alt="ABOUT"/>
                        <img src="assets/img/images/6.png" alt="ABOUT"/>
                        <img src="assets/img/images/7.png" alt="ABOUT"/>
                        <img src="assets/img/images/8.png" alt="ABOUT"/>
                        <img src="assets/img/images/9.png" alt="ABOUT"/>
                        <img src="assets/img/images/10.png" alt="ABOUT"/>
                        <img src="assets/img/images/11.png" alt="ABOUT"/>
                        <img src="assets/img/images/12.png" alt="ABOUT"/>
                        <img src="assets/img/images/13.png" alt="ABOUT"/>
                        <img src="assets/img/images/14.png" alt="ABOUT"/>
                        <img src="assets/img/images/15.png" alt="ABOUT"/>
                    </div>
                    <div className="about__slider__right">
                        <img src="assets/img/images/16.png" alt="ABOUT"/>
                        <img src="assets/img/images/17.png" alt="ABOUT"/>
                        <img src="assets/img/images/18.png" alt="ABOUT"/>
                        <img src="assets/img/images/19.png" alt="ABOUT"/>
                        <img src="assets/img/images/20.png" alt="ABOUT"/>
                        <img src="assets/img/images/21.png" alt="ABOUT"/>
                        <img src="assets/img/images/22.png" alt="ABOUT"/>
                        <img src="assets/img/images/23.png" alt="ABOUT"/>
                        <img src="assets/img/images/24.jpg" alt="ABOUT"/>
                        <img src="assets/img/images/25.png" alt="ABOUT"/>
                        <img src="assets/img/images/26.png" alt="ABOUT"/>
                        <img src="assets/img/images/27.png" alt="ABOUT"/>
                        <img src="assets/img/images/28.png" alt="ABOUT"/>
                        <img src="assets/img/images/29.png" alt="ABOUT"/>
                        <img src="assets/img/images/30.png" alt="ABOUT"/>
                    </div>
                    <div className="bottom-shadow"></div>
                </div>
                <div className="about__content__wrap">
                    <div className="section-title mb-30">
                        <span className="sub-title">KATANA INU</span>
                        <h2 className="title">
                            <span>Secure Your GEN 1 Forge Champions NFT</span> & Unlock an Unparalleled Utility Experience!
                        </h2>
                    </div>
                    <div className="play_content about-content mt-4">
                        <ul>
                            <li>The Forging Collection: Unleash your PFP's true potential with our Forge Champions Gen 1!</li>
                            <li>Revolutionary Forging Mechanism becomes available in ~3 months: This will allow you to transform your Forge Champions NFTs into exclusive in-game assets. This cannot be minted anywhere else!</li>
                            <li>You will be able to burn the NFTs from this mint, initially used as PFPs, to create an in-game one-of-a-kind character or weapon skin, never obtainable again.</li>
                            <li>Early access to testnet in just under 1 month from mint date: Be the first to explore in-game Katana Inu content and earn tokens with the ultimate P2E experience!</li>
                            <li>Fully on-chain gameplay access (on testnet) if you own a Forge Champion NFT, you can already immerse in the AAA Web3 gaming experience</li>
                            <li>Stand out with exclusive Forge Icon Discord Role in the Katana Inu server and get exclusive access to Holders Chat</li>
                            <li>Dominate in-game PVP mode with unlimited access for all our NFT holders</li>
                            <li>Previous mint holders are automatically whitelisted: Your loyalty is rewarded!</li>
                        </ul>
                        <div className="banner-btn gotomint">
                            <a className="btnd mt-30" target="_blank" rel="noreferrer" href='https://mint.katanainu.com/'>Go to Mint</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Nft