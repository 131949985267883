import Banner from "../components/Banner";
import Back from "../components/Back";
import About from "../components/About";
import MainLayout from "../layouts/MainLayout";
import BackLayout from "../layouts/BackLayout";
import Gallery from "../components/Garallery";
import Ecosystem2 from "../components/Ecosystem2";
import Nft from "../components/Nft";
import { Dots } from "react-preloaders2";
import { useEffect, useState } from "react";
import CheckWhitelisted from "../components/CheckWhitelisted";

const Home = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <>
      <MainLayout>
        <Banner />
        <BackLayout>
          <Back />
          <About />
          <Gallery />
          <Nft />
          <Ecosystem2 />
          <CheckWhitelisted />
        </BackLayout>
      </MainLayout>
      <Dots color="#f3a511" customLoading={isLoading} />
    </>
  );
};

export default Home;
