import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useState } from "react";
// import twitter from "/assets/img/whitelisted/twitter.svg"
// import telegram from "/assets/img/whitelisted/telegram.svg"
// import discord from "/assets/img/whitelisted/discord.svg"


// const iconList = [
//     {icon: twitter},
//     {icon: discord},
//     {icon: telegram},
// ]
const schema = z.object({
    address: z
      .string()
      .trim()
      .min(1, { message: "Enter your wallet address" })
      .regex(/^[a-z0-9A-Z]+$/gm, {
        message:
          "Please choose a stronger password. Try a mix of letters, numbers, and symbols.",
      })
  })

const CheckWhitelisted = () =>{
    let hostAPI = "https://api-stag-ktn.esollabs.com";
    if(process.env.NODE_ENV === 'production') {
        hostAPI = "https://api.katanainu.com"
    }
    const [step, setStep] = useState(1)
    const { register, handleSubmit, formState: { errors } } = useForm({defaultValues: {address: ""}, resolver: zodResolver(schema),});
    const onSubmit = async(data) => {
        try {
            const res = await fetch(`${hostAPI}/v1/dapp/whitelist/check`, {
              method: "POST",
              body: JSON.stringify(data),
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (res.ok) {
              const result = await res.json();
              if(result?.data?.is_valid_whitelist) {
                setStep(2)
              }else {
                setStep(3)
              }
            }

            if (res.status === 400) {
              // bad input
              console.log("err-------")
              return;
            }

            throw res;
          } catch (e) {
            console.log("Something went wrong! Please try later!");
          } finally {
          }
    }
    return (
        <div id="checker" className="banner-whitelisted">
            {/* <div></div> */}
            <div className="whitelisted-container">
                {step === 1 ? (
                    <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <div className="whitelisted-title">Check if your wallet is whitelisted:</div>
                    <input placeholder="Add your wallet address here" className="whitelisted-input" {...register("address")} error={errors.address ? true : false}/>
                     {errors.address && (
                        <div className="error">
                        {errors.address.message}
                      </div>
                     )}
                    <button type="submit" className="whitelisted-btn">Check now</button>
                    </form>
                ) : step === 2 ? (
                    <div className="whitelisted-wrapper">
                        <div className="success">Congratulations</div>
                        <div className="whitelisted-content">Your wallet has been whitelisted</div>
                        <div className="whitelisted-highlight">Minting Coming soon</div>
                        <div>
                            <div className="whitelisted-highlight">Stay tuned</div>
                            <ul className="menu-icon">
                                <li><a className="icon-item" target="_blank" rel="noreferrer" href="https://twitter.com/katanainu"><i className="fab fa-twitter" /></a></li>
                                <li><a className="icon-item" target="_blank" rel="noreferrer" href="https://discord.com/invite/katanainu"><i className="fab fa-discord" /></a></li>
                                <li><a className="icon-item" target="_blank" rel="noreferrer" href="https://t.me/katanainu"><i className="fab fa-telegram" /></a></li>
                            </ul>
                        </div>
                    </div>
                ): (
                    <div className="whitelisted-wrapper">
                        <div className="failure">Sorry! </div>
                        <div className="whitelisted-content">you are not in the whitelist</div>
                        <div className="whitelisted-content1">Click here to get a spot:</div>
                        <a href="https://whitelist.katanainu.com/" target="_blank" rel="noreferrer"><button className="failure-btn">whitelist.katanainu.com</button></a>
                        <p>We are updating this checker constatly, and will update at 10th may the latest.
                        So check this again at latest 10th of May.</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CheckWhitelisted;