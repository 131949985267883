
const About = () =>{

    return (
        <section className="mission-area" id="tokenomics">
                <div className="container">
                <div className="row align-items-center">

                    <div className="timer_count banner-btn">
                        <a className="btnd mb-30 mint_csoon" target="_blank" rel="noreferrer" href='https://mint.katanainu.com/'>Mint Here <span>Now</span></a>
                    </div>

                    <div className="col-lg-5 order-0 order-lg-2">
                        <div className="mission-img text-center mission_gif">
                            <img src="assets/img/images/38431.gif" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="section-title mb-20 mint_title mt-30 text-center">
                            <h2 className="title nft_title"><span>FORGING COLLECTION </span> MINT OVERVIEW</h2>

                        </div>
                        <div className="mission-content text-center c_buy_d">
                            <p>As a member of the KataArmy, you're part of an elite group of forward-thinking Web3 OG’s who understand the power of cutting-edge technology.</p>
                            <p>By joining the whitelist, you'll have the chance to mint your very own Forge 3D in-game NFTs in Katana Inu Game, which cannot be minted anywhere else and making them exclusive to the forging platform.</p>

                            <p>This 2D forging NFTs are classified as deflationary due to the burning process that takes place after they are forged.</p>

                            <p>You can use it as FPF's or you can use it to forge new 3D in-game items.</p>
                            <p>The Forging-system will be ready soon.
                            </p>
                            <p className='text-white mt-3 comming-soon-title'>Coming in 11th May 2023.</p>
                            <p className='text-white mt-3 mb-3 comming-soon-title'>Total Supply: 5555 NFTs</p>
                            <h3 className="c_rate font-weight-bold"><span>Mint Price with Whitelist Spot:</span> 0.035 ETH</h3>
                            <h3 className="c_rate font-weight-bold mb-50"><span>Mint Price without a Whitelist Spot:</span> 0.040 ETH</h3>
                            <div className='banner-btn nft-btn'>
                                <a className="btnd" target="_blank" rel="noreferrer" href='https://discord.com/invite/katanainu'>Join Discord Now</a>
                                <a className="btnd" target="_blank" rel="noreferrer" href='https://mint.katanainu.com/'>Mint Now</a>
                                <a class="btnd" href="/#checker">Whitelist Checker</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
    )
}

export default About