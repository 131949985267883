import Slider from "react-slick"
// import { } from "react"

const Prev = (props) =>{
    const { className, onClick } = props
    return (
        <button type="button" className={ className + " slick-prev"} onClick={onClick}><img src="assets/img/icons/gallery_left_arrow.png" alt=""/></button>
    )
}

const Next = (props) =>{
    const { className, onClick } = props
    return (
        <button type="button" className={ className + " slick-next" } onClick={onClick}><img src="assets/img/icons/gallery_right_arrow.png" alt=""/></button>
    )
}

const Gallery = () =>{

    const gallery = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: false,
        centerMode: true,
        centerPadding: '440px',
        className:'row gallery-active',
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <Prev/>,
        nextArrow: <Next/>,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    centerPadding: '300px',
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    centerPadding: '200px',
                }
            },
            {
                breakpoint: 992,
                settings: {
                    centerPadding: '80px',
                }
            },
            {
                breakpoint: 767,
                settings: {
                    centerPadding: '50px',

                }
            },
            {
                breakpoint: 575,
                settings: {
                    centerPadding: '15px',

                }
            },
        ]
    }



    return (
        <>
            {/* gallery-area */}
            <section className="gallery-area">

                <div className="container">
                    <Slider {...gallery} ref={(slider1) => (slider1)}>
                        <div className="col">
                        <div className="gallery-items">
                            <div className="gallery-thumb">
                            <img src="assets/img/trailers/trailer00.png" alt="img" />
                            <a href="https://www.youtube.com/watch?v=7nykO0FzsVQ" className="popup-video"><i className="fas fa-play" /></a>
                            </div>
                        </div>
                        </div>
                        <div className="col">
                        <div className="gallery-items">
                            <div className="gallery-thumb">
                            <img src="assets/img/trailers/trailer01.png" alt="img" />
                                    <a href="https://www.youtube.com/watch?v=9c7pDf-WFAk" className="popup-video"><i className="fas fa-play" /></a>
                            </div>
                        </div>
                        </div>
                        <div className="col">
                        <div className="gallery-items">
                            <div className="gallery-thumb">
                            <img src="assets/img/trailers/trailer03.jpg" alt="img" />
                            <a href="https://www.youtube.com/watch?v=TKKWnzLZMKo" className="popup-video"><i className="fas fa-play" /></a>
                            </div>
                        </div>
                        </div>
                    </Slider>
                </div>

            </section>
            {/* gallery-area-end */}
        </>
    )
}

export default Gallery