import React from 'react';


const Back = () => {

    return (

            <div className="brand-area brand-bg home-four-brand">
                <div className="container">
                    <div className='row align-items-center justify-content-center'>
                        <div className='col-md-7'>
                            <h2 data-animation="fadeInUp" data-delay=".4s">Minting Available Now, <span>Good luck!</span>
                            </h2>
                        </div>
                        <div className='col-md-5'>

                            <div className='banner-btn band_btn'>
                                    <a className="btnd" target="_blank" rel="noreferrer" href='https://mint.katanainu.com/'>Mint Now</a>
                                    <a class="btnd" href="/#checker">Whitelist Checker</a>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

    )
}

export default Back